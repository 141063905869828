// Function to initialize scrolling for a specific parent container
function initializeScrolling(parent) {
    const rightArrow = parent.querySelector('.arrow-button-right');
    const leftArrow = parent.querySelector('.arrow-button-left');
    const topArrow = parent.querySelector('.arrow-button-top');
    const bottomArrow = parent.querySelector('.arrow-button-bottom');
    const target = parent.querySelector(parent.getAttribute('data-scroll-target'));
    let gapValue = calculateGap(target);
    let currentIndex = 0; // To keep track of the current index

    if (rightArrow)
    rightArrow.addEventListener('click', () => {
        const lastChild = target.children[target.children.length - 1];

        // Calculate the right edge of the last child
        const lastChildRightEdge = lastChild.offsetLeft + lastChild.offsetWidth;

        // Calculate the current scroll position and the width of the target
        const currentScrollPosition = target.scrollLeft + target.offsetWidth;

        // Check if the last child is fully visible
        if (currentScrollPosition >= lastChildRightEdge) {
            return; // Do not scroll if the last child is fully visible
        }

        const width = target.children[0].offsetWidth + gapValue;
        target.scrollBy({
            left: width,
            behavior: 'smooth'
        });

        currentIndex++; // Increment the index after scrolling right
    });

    if (leftArrow)
    leftArrow.addEventListener('click', () => {
        // if (currentIndex <= 0) {
        //     return;
        // }

        const width = target.children[0].offsetWidth + gapValue;
        target.scrollBy({
            left: -width,
            behavior: 'smooth'
        });

        currentIndex--; // Decrement the index after scrolling left
    });

    if (bottomArrow)
    bottomArrow.addEventListener('click', () => {
        const lastChild = target.children[target.children.length - 1];
        const lastChildRightEdge = lastChild.offsetTop + lastChild.offsetHeight;
        const currentScrollPosition = target.scrollTop + target.offsetHeight;
        if (currentScrollPosition >= lastChildRightEdge) {
            return; // Do not scroll if the last child is fully visible
        }

        const width = target.children[0].offsetHeight;
        target.scrollBy({
            top: width,
            behavior: 'smooth'
        });
    });
    
    if (topArrow)
    topArrow.addEventListener('click', () => {
        const width = target.children[0].offsetHeight;

        target.scrollBy({
            top: -width,
            behavior: 'smooth'
        });
    });

    // let wheelin = false

    // target.addEventListener('wheel', () => {
    //     console.log("wheel")
    //     wheelin = true
    // })

    // target.addEventListener('scroll', () => {
    //     const scrollLeft = target.scrollLeft;
    //     let newIndex = 0;

    //     // Loop through children to find the currently aligned element
    //     for (let i = 0; i < target.children.length; i++) {
    //         const child = target.children[i];
    //         const childLeftEdge = child.offsetLeft;
    //         const childRightEdge = childLeftEdge + child.offsetWidth;

    //         // Check if the child is aligned on the left side
    //         if (scrollLeft >= childLeftEdge && scrollLeft < childRightEdge) {
    //             newIndex = i;
    //             break;
    //         }
    //     }
    //     console.log(newIndex)

    //     // Update currentIndex if it has changed
    //     if (newIndex !== currentIndex) {
    //         //currentIndex = newIndex;
    //     }
    // });
}

function calculateGap(target) {
    let gapValue = window.getComputedStyle(target).getPropertyValue("gap");

    if (gapValue === 'normal') {
        return 0;
    } else if (gapValue.endsWith('%')) {
        const containerWidth = target.clientWidth;
        const gapPercentage = parseFloat(gapValue);
        gapValue = (gapPercentage / 100) * containerWidth;
    } else {
        gapValue = parseInt(gapValue);
    }

    return gapValue;
}

// Function to initialize scrolling for all containers on the page
function initializeAllScrollContainers() {
    const containers = document.querySelectorAll('[data-scroll-target]'); // Select all scroll containers
    containers.forEach(container => {
        initializeScrolling(container); // Initialize scrolling for each container
    });
}

// Call the function to initialize all scroll containers
initializeAllScrollContainers();
