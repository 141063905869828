export function fetchNews(e) {
    return;
    e.preventDefault();

    fetch(e.target.href)
        .then(response => response.text())
        .then(html => {
            const parser = new DOMParser()

            const doc = parser.parseFromString(html, "text/html")
            //const header = 
            const mainContent = doc.querySelector('.main-content'); // Change this selector to match your content
            if (mainContent) {
                openModal(mainContent.innerHTML); // Open modal with the extracted content
            } else {
                console.error('Main content not found in the fetched HTML.');
            }
        })
        .catch(error => console.error('Error fetching news:', error));

    function openModal(content) {
        const modal = document.getElementById('newsModal');
        modal.style.display = 'block';
        const modalContent = modal.querySelector('.modal-content');
        document.getElementById('news-target').innerHTML = content; // Set the content of the modal
        setTimeout(() => {
            modalContent.classList.add('show');
        }, 10); // Allow time for the modal to be displayed before adding the class
    
        // Freeze the background
        document.body.style.overflow = 'hidden';
    }
}