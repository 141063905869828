import { smoothScrollTo } from './utils.js';

document.addEventListener("DOMContentLoaded", () => {
    distributeItems();
    window.addEventListener('resize', distributeItems);

    const snapContainers = document.querySelectorAll('.snap-behavior');
    snapContainers.forEach(createDots);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    screen.orientation.onchange = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    animateHeroSlide();
});

window.addEventListener('load', () => {
    setTimeout(() => {
        var loaders = document.querySelectorAll('[data-src]');

        loaders.forEach(loader => {
            var imgSrc = loader.getAttribute('data-src');

            var img = document.createElement('img');
            img.src = imgSrc;

            img.alt = "Hauptansicht auf das Projekt";

            img.addEventListener('load', () => {
                loader.replaceWith(img);
            });
        });
    }, 500); // 500 milliseconds delay so that the rest of page can be rendered first
});

let currentSliderIndex = 0;

function animateHeroSlide() {
    const imageControls = document.querySelector(".progress-container")

    if (!imageControls)
        return;

    const progressBar = imageControls.children[0];

    progressBar.addEventListener("animationend", () => {
        moveToNext(progressBar)
    });
    progressBar.classList.add("active")

    var parent = document.querySelector('.image-controls');
    var right = parent.querySelector('.arrow-button-right')
    var left = parent.querySelector('.arrow-button-left')

}

function moveToNext(target) {
    var parent = document.querySelector('.image-slider');
    const lastChild = parent.children[parent.children.length - 1];

    // Calculate the right edge of the last child
    const lastChildRightEdge = lastChild.offsetLeft + lastChild.offsetWidth;

    // Calculate the current scroll position and the width of the target
    const currentScrollPosition = parent.scrollLeft + parent.offsetWidth;

    // Check if the last child is fully visible
    if (currentScrollPosition >= lastChildRightEdge) {
        return; // Do not scroll if the last child is fully visible
    }

    const width = parent.children[0].offsetWidth;
    parent.scrollBy({
        left: width,
        behavior: 'smooth'
    });

    var index = (currentScrollPosition / parent.children[0].offsetWidth);
    index = Math.round(index);
    console.log(index)

    if (index < parent.children.length) {
        const next = document.querySelector(".progress-container").children[index];
        document.querySelector(".counter-container").innerHTML = "0" + (index+1) + " / 0" + parent.children.length;
        next.classList.add("active")
        next.addEventListener("animationend", () => moveToNext(next));
    }


    return;
    var imageSlider = document.querySelector(".image-slider");
    const targetScrollLeft = ((++currentSliderIndex) * imageSlider.children[0].offsetWidth);
    smoothScrollTo(imageSlider, targetScrollLeft, 300); // Smoothly scroll to the target position
    
    const next = target.nextElementSibling
    
    target.classList.remove("active")
    target.classList.add("done")
    
    if (next) {
        document.querySelector(".counter-container").innerHTML = "0" + (currentSliderIndex+1) + " / 0" + imageSlider.children.length;
        next.classList.add("active")
        next.addEventListener("animationend", () => moveToNext(next));
    }
}

function createDots(container) {
    const childCount = container.children.length;
    
    // Get the next sibling element where dots will be added
    const dotsContainer = container.nextElementSibling;
    
    if (!dotsContainer || !dotsContainer.classList.contains('dot-container')) {
        return; // some snap containers don't have dots
    }
    
    // Clear any existing dots
    dotsContainer.innerHTML = '';
    
    // Create and append the necessary number of dots only if there is more than one child
    if (childCount > 1) {
        for (let i = 0; i < childCount; i++) {
            const dot = document.createElement('span');
            dot.classList.add('dot');
            dotsContainer.appendChild(dot);
        }
        
        const dots = Array.from(dotsContainer.children);
        
        // maybe not necessary
        container.removeEventListener('scroll', updateDots);
        container.addEventListener('scroll', updateDots);
            
        if (dots.length > 0) {
            dots[0].classList.add('active');
        }
    }
}

function updateDots(e) {
    const container = e.target;
    const cardWidth = container.clientWidth;
    const scrollLeft = container.scrollLeft;
    const currentIndex = Math.round(scrollLeft / cardWidth);

    const dotsContainer = container.nextElementSibling;

    const dots = Array.from(dotsContainer.children);
    
    // Update the active dot based on the current index
    dots.forEach((dot, index) => {
        if (index === currentIndex) {
            dot.classList.add('active');
        } else {
            dot.classList.remove('active');
        }
    });
}

let currentResolution = '';

function distributeItems() {
    const resolution = window.innerWidth < 768 ? 'sm' : 'md';

    if (currentResolution === resolution) {
        return;
    } else {
        currentResolution = resolution;
    }

    const containers = document.querySelectorAll('.dynamic-snap');

    // If no containers are found, do nothing
    if (containers.length === 0) {
        return;
    }

    containers.forEach(container => {
        let children = Array.from(container.children);

        children.forEach(child => {
            if (/(-container)$/.test(child.className)) {
                // If the child has the -container class, replace it with its children
                const childChildren = Array.from(child.children);
                childChildren.forEach(grandChild => {
                    container.appendChild(grandChild); // Append each grandchild to the parent container
                });
                container.removeChild(child); // Remove the -container child
            }
        });

        // Now, you can get the updated list of children without -container elements
        children = Array.from(container.children);

        const itemsPerRow = parseInt(container.getAttribute('data-items-per-row' + (resolution == 'sm' ? '' : '-md')), 10);

        if (itemsPerRow > 1) {
            for (let i = 0; i < children.length; i += itemsPerRow) {
                const groupDiv = document.createElement('div');
                
                let containerClass = 'pair-container';
                if (itemsPerRow == 3) { containerClass = 'triple-container' }
                else if (itemsPerRow == 5) {containerClass = 'penta-container' }
                
                groupDiv.classList.add(containerClass);
                
                for (let j = 0; j < itemsPerRow; j++) {
                    if (i + j < children.length) {
                        groupDiv.appendChild(children[i + j]);
                    }
                }
            
                container.appendChild(groupDiv);
            }
        } else {
            for (let i = 0; i < children.length; i++) {
                container.appendChild(children[i])
            }
        }

        createDots(container);
    });
}