function toggleMenu() {
    const header = document.querySelector('header');

    if (header.classList.contains("opened")) {
        header.classList.remove("opened")
    } else {
        header.classList.add("opened")
    }
}

export function toggleDropdown(event) {
    const submenu = event.target.closest('li').querySelector('.submenu');
    submenu.style.display = getComputedStyle(submenu).getPropertyValue("display") === 'block' ? 'none' : 'block';
}

document.addEventListener("DOMContentLoaded", () => {
    document.querySelector('#menu-toggler').addEventListener('click', toggleMenu);
    document.querySelector('.dropdown-button').addEventListener('click', toggleDropdown);
    
    const header = document.querySelector('header');
    window.addEventListener('scroll', () => {
        if (window.pageYOffset > 0) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    });
})