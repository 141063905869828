import { fetchNews } from "./newsfeed";

document.addEventListener('DOMContentLoaded', function() {
    const searchForm = document.getElementById('search-form');
    const searchResults = document.getElementById('search-results');
    const searchInput = document.getElementById('search-input');

    if (!searchForm) {
        return;
    }
    
    const url = searchForm.action;

    function performSearch() {
        //e.preventDefault(); // Prevent the default form submission

        const query = searchForm.query.value; // Get the value from the input field

        // Fetch the search results
        fetch(url + "?query=" + encodeURIComponent(query))
            .then(response => response.text())
            .then(data => {
                const tempContainer = document.createElement('div');
                tempContainer.innerHTML = data;

                // Get all top-level divs
                const topLevelDivs = Array.from(tempContainer.children).filter(child => child.tagName === 'DIV');

                // Create a new HTML string with <hr> between top-level divs
                const resultWithHr = topLevelDivs.map(div => div.outerHTML).join('<hr>');

                // Load the results into the div
                searchResults.innerHTML = resultWithHr;

                const newsLinks = document.querySelectorAll('.text-link');

                // Add a click event listener to each link
                newsLinks.forEach(link => {
                    if (link.classList.contains("read-news")) {
                        link.addEventListener('click', fetchNews);
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching search results:', error);
            });
    }

    fetch(url)
        .then(response => response.text())
        .then(data => {
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = data;

            // Get all top-level divs
            const topLevelDivs = Array.from(tempContainer.children).filter(child => child.tagName === 'DIV');

            // Create a new HTML string with <hr> between top-level divs
            const resultWithHr = topLevelDivs.map(div => div.outerHTML).join('<hr>');

            // Load the results into the div
            searchResults.innerHTML = resultWithHr;

            const newsLinks = document.querySelectorAll('.text-link');

            // Add a click event listener to each link
            newsLinks.forEach(link => {
                if (link.classList.contains("read-news")) {
                    link.addEventListener('click', fetchNews);
                }
            });
        })
        .catch(error => {
            console.error('Error fetching search results:', error);
        });

    let timeout;

    // Handle Enter key submission
    searchInput.addEventListener('keypress', function(event) {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            performSearch(searchInput.value);
        }
    });

    // Handle debounced search
    searchInput.addEventListener('input', function() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            performSearch(searchInput.value);
        }, 500);
    });
});