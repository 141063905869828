/**
 * Smoothly scrolls an element to a specified target position over a given duration.
 *
 * This function disables scroll snapping during the animation and re-enables it once the animation is complete.
 *
 * @param {HTMLElement} element - The DOM element to be scrolled.
 * @param {number} target - The target scroll position (in pixels) to scroll to.
 * @param {number} duration - The duration of the scroll animation (in milliseconds).
 *
 * @example
 * const scrollableElement = document.querySelector('.scrollable');
 * const targetPosition = 500; // Scroll to 500 pixels
 * const duration = 600; // Animation duration of 600 milliseconds
 * smoothScrollTo(scrollableElement, targetPosition, duration);
 */
function smoothScrollTo(element, target, duration) {
    const start = element.scrollLeft;
    const change = target - start;
    const increment = 20; // Time between each frame in ms
    let currentTime = 0;

    function animateScroll() {
        currentTime += increment;
        const val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollLeft = val;
        if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
        } else {
            // Re-enable scroll snap after animation
            element.style.scrollSnapType = 'x mandatory';
        }
    }

    // Easing function
    function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }

    // Disable scroll snap before starting the animation
    element.style.scrollSnapType = 'none';
    animateScroll();
}

export { smoothScrollTo };