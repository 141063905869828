import { smoothScrollTo } from './utils.js';

document.addEventListener('DOMContentLoaded', function() {
    const timelineContainer = document.querySelector('.timeline');
    const milestoneContainer = document.querySelector('.milestone-container');
    const years = document.querySelectorAll('.year');
    const milestones = document.querySelectorAll('.milestone');
    const leftButton = document.querySelector('.left');
    const rightButton = document.querySelector('.right');
    const gap = 20;
    let currentIndex = 0; // Track the current index of the year

    if (!timelineContainer) {
        return;
    }

    milestoneContainer.addEventListener('scroll', CalculateTimeLineOffset);
    window.addEventListener('resize', CalculateTimeLineOffset);

    // Button click event listeners
    leftButton.addEventListener('click', () => {
        if (currentIndex > 0) {
            currentIndex--; // Decrement index
            let scrollWidth = window.innerWidth < 768 ? 33.3 : 25;
            const targetScrollLeft = (currentIndex * milestones[0].offsetWidth) + (currentIndex * gap); // Calculate target scroll position
            smoothScrollTo(milestoneContainer, targetScrollLeft, 300);
            timelineContainer.style.transform = `translateX(${-currentIndex * scrollWidth}%)`; // Update timeline position
        }
    });

    rightButton.addEventListener('click', () => {
        if (currentIndex < years.length - 1) {
            currentIndex++; // Increment index
            let scrollWidth = window.innerWidth < 768 ? 33.3 : 25;
            const targetScrollLeft = (currentIndex * milestones[0].offsetWidth) + (currentIndex * gap); // Calculate target scroll position
            smoothScrollTo(milestoneContainer, targetScrollLeft, 300); // Smoothly scroll to the target position
            timelineContainer.style.transform = `translateX(${-currentIndex * scrollWidth}%)`; // Update timeline position
        }
    });

    // Initial centering
    milestoneContainer.scrollLeft = 0; // Start at the first milestone
    timelineContainer.style.transform = `translateX(0%)`; // Center the first year

    function CalculateTimeLineOffset() {
        const scrollLeft = milestoneContainer.scrollLeft;
        const milestoneWidth = milestones[0].offsetWidth;
    
        // Calculate the index of the currently centered milestone
        const index = Math.round(scrollLeft / milestoneWidth);
    
        let scrollWidth = window.innerWidth < 768 ? 33.3 : 25;
    
        // Ensure the index is within bounds
        const clampedIndex = Math.max(0, Math.min(index, years.length - 1));
        timelineContainer.style.transform = `translateX(${-clampedIndex * scrollWidth}%)`;
        currentIndex = clampedIndex; // Update current index
    }
});